/** @module lib/core/bibli */
import type { GraphJson } from 'src/lib/entities/Graph'
import type { RessourceJ3pBibli, LegacyGraph } from 'src/lib/types'

const biblis: Record<string, string> = {
  sesabibli: 'https://bibliotheque.sesamath.net/api/public/',
  sesacommun: 'https://commun.sesamath.net/api/public/',
  sesabidev: 'https://bibliotheque.sesamath.dev/api/public/',
  sesacomdev: 'https://commun.sesamath.dev/api/public/'
}

interface LoadBibliResult {
  ressource: RessourceJ3pBibli
  graphe: LegacyGraph | GraphJson
  editgraphes?: {
    positionNodes: number[][]
    titreNodes: string[]
  }
}

/**
 * Retourne l’url permettant de récupérer la ressource
 * @param rid
 * @throws Error si rid est invalide (ou fait référence à une bibli inconnue)
 */
function getUrl (rid: string): string {
  const chunks = rid.split('/')
  if (chunks.length !== 2) throw Error(`rid ${rid} invalide`)
  const [baseId, id] = chunks
  if (typeof baseId !== 'string' || typeof id !== 'string') throw Error(`rid ${rid} invalide`)
  if (!(baseId in biblis)) throw Error(`baseId ${baseId} inconnue`)
  return biblis[baseId] + id
}

/**
 * Retourne la ressource récupérée sur sa bibli
 * @param rid
 */
export async function loadBibli (rid: string): Promise<LoadBibliResult> {
  const url = getUrl(rid)
  const response = await fetch(url)
  if (!response.ok) throw Error(`HTTP error ${response.status} ${response.statusText} on ${url}`)
  const { data: ressource } = await response.json()
  if (ressource.type !== 'j3p') throw Error(`La ressource ${rid} n’est pas une ressource j3p`)
  if (ressource.parametres.g) {
    return { ressource, graphe: ressource.parametres.g, editgraphes: ressource.parametres.editgraphes }
  }
  if (ressource.parametres.graph) {
    return { ressource, graphe: ressource.parametres.graph }
  }
  throw Error('Ressource j3p invalide, sans parametres.g ni parametres.graph')
}
